<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-modal
      id="modal-create"
      v-model="isModalMitra"
      size="lg"
      centered
      title="LAKERMA IA"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label for="judulPks">Judul Pks:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="judulPks"
            v-model="modal.judulPks"
            required
            max-length="50"
            :disabled="modal.judulPks !== null"
          />
        </b-form-group>
        <b-form-group>
          <label for="referensiKerjaSama">Referensi KS:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="referensiKerjaSama"
            v-model="modal.referensiKerjaSama"
            required
            max-length="50"
            :disabled="modal.referensiKerjaSama !== null"
          />
        </b-form-group>
        <b-form-group>
          <label for="mitraKerjasama">Mitra KS:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="mitraKerjasama"
            v-model="modal.mitraKerjasama"
            required
            :disabled="modal.mitraKerjasama !== null"
          />
        </b-form-group>
        <b-form-group>
          <label for="ruangLingkup">Ruang Lingkup:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="ruangLingkup"
            v-model="modal.ruangLingkup"
            required
            :disabled="modal.ruangLingkup !== null"
          />
        </b-form-group>
        <b-form-group>
          <label for="namaPj">Nama Pj:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="namaPj"
            v-model="modal.namaPj"
            required
            :disabled="modal.namaPj !== null"
          />
        </b-form-group>
        <b-form-group>
          <label for="nipPj">Nip Pj:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="nipPj"
            v-model="modal.nipPj"
            required
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group>
          <label for="jabatanPj">Jabatan Pj:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary id="jabatanPj" v-model="modal.jabatanPj" required />
        </b-form-group>
        <b-form-group>
          <label for="mitra">Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="mitra"
            v-model="modal.mitra"
            required
            :disabled="modal.mitra !== null"
          />
        </b-form-group>
        <b-form-group>
          <label for="namaMitra">Nama Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="namaMitra"
            v-model="modal.namaMitra"
            required
            :disabled="modal.namaMitra !== null"
          />
        </b-form-group>
        <b-form-group>
          <label for="namaMengetahui">Nama Mengetahui:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="namaMengetahui"
            v-model="modal.namaMengetahui"
            required
          />
        </b-form-group>
        <b-form-group>
          <label for="jabatanMengetahui">Jabatan Mengetahui:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="jabatanMengetahui"
            v-model="modal.jabatanMengetahui"
            required
          />
        </b-form-group>
        <b-form-group>
          <label for="nipMengetahui">Nip Mengetahui:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="nipMengetahui"
            v-model="modal.nipMengetahui"
            required
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group>
          <label for="outcome">Outcome:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary id="outcome" v-model="modal.outcome" required />
        </b-form-group>
        <b-form-group>
          <label for="linkLaporan">Link Laporan:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="linkLaporan"
            v-model="modal.linkLaporan"
            required
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group>
              <label for="linkLaporan">Upload LAKERMA:</label
              ><span class="text-danger ml-1">*</span>
              <b-form-file
                accept=".pdf"
                placeholder="Berkas LAKERMA"
                id="dokumenLakerma"
                ref="refDokumenLakerma"
                v-model="modal.dokumenLakerma"
                @input="inputUploadDokumen"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col
            class="align-self-center mt-2"
            :class="
              dataDokumenLakerma ? 'cursor-pointer' : 'cursor-not-allowed'
            "
            md="4"
            v-b-tooltip="
              dataDokumenLakerma ? 'Lihat Dokumen' : 'File tidak ditemukan'
            "
          >
            <b-button
              v-if="dataDokumenLakerma"
              variant="primary"
              @click="showDokumenLakerma"
              ><i class="ri-search-line mr-1"></i>Lihat Dokumen</b-button
            >
            <b-button v-else variant="default" disabled
              ><i class="ri-search-line mr-1"></i>Lihat Dokumen</b-button
            >
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalMitra">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="simpanData"
              :disabled="
                modal.linkLaporan === null ||
                  modal.outcome === null ||
                  modal.nipMengetahui === null ||
                  modal.jabatanMengetahui === null ||
                  modal.namaMengetahui === null ||
                  modal.namaMitra === null ||
                  modal.jabatanPj === null ||
                  modal.nipPj === null ||
                  modal.namaPj === null ||
                  modal.ruangLingkup === null ||
                  modal.mitraKerjasama === null ||
                  modal.referensiKerjaSama === null ||
                  modal.judulPks === null
              "
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-btn
            class="btn-block"
            variant="primary"
            @click="uploadDokumenLakerma"
            :disabled="!modal.dokumenLakerma"
            >Upload LAKERMA</b-btn
          >
        </b-row>
      </b-form>
    </b-modal>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Daftar Data IA</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-50"
                    id="filter-input"
                    v-model="filterIa"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <div class="d-inline-block float-right">
                    <router-link
                      class="btn btn-primary dark-btn-primary"
                      :to="{ name: 'kerjasama.ia.tambah' }"
                      role="button"
                      >Tambah Data<i class="ri-add-line ml-2"></i
                    ></router-link>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoadingIa"
                :filter="filterIa"
                :per-page="perPageIa"
                :current-page="currentPageIa"
                @filtered="onFilteredIa"
                show-empty
              >
                <template v-slot:cell(kodeIa)="listData">
                  <div>
                    {{ listData.item.kodeIa ? listData.item.kodeIa : '-' }}
                  </div>
                </template>
                <template v-slot:cell(aksi)="listData">
                  <div class="d-flex">
                    <router-link
                      class="btn iq-bg-success rounded-sm mr-2 mb-1"
                      size="sm"
                      role="button"
                      :to="{
                        name: 'kerjasama.ia.edit',
                        params: { idIa: listData.item.idIa }
                      }"
                      ><i :class="'ri-ball-pen-fill m-0'"></i
                    ></router-link>
                    <router-link
                      class="btn iq-bg-info rounded-sm ml-2 mb-1"
                      v-b-tooltip="'Lihat Detail'"
                      size="sm"
                      role="button"
                      :to="{
                        name: 'kerjasama.ia.detail',
                        params: { idIa: listData.item.idIa }
                      }"
                      ><i class="ri-search-line m-0"></i
                    ></router-link>
                    <b-button
                      class="btn iq-bg-danger rounded-sm ml-2 mb-1"
                      variant="primary"
                      @click="
                        showDokumenKs(
                          listData.item.idIa,
                          listData.item.nomorSuratUt
                        )
                      "
                      ><i class="ri-file mr-1"></i>Cetak Dokumen</b-button
                    >
                    <!-- @click="showEditMitra(listData.items.idIa)" -->
                    <!-- v-b-modal.modal-create -->
                    <b-button
                      class="ml-2 mb-1"
                      variant="info"
                      @click="
                        cariLakerma(listData.item.kodeIa, listData.item.idIa)
                      "
                      ><i class="ri-file mr-1"></i>Lihat Lakerma</b-button
                    >
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
                <template #emptyfiltered>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageIa"
                      :options="pageOptionsIa"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageIa"
                    :total-rows="totalRowsIa"
                    :per-page="perPageIa"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { mapState } from 'vuex'
import { ID_GROUP } from '@/types'
/** Date Format */
import moment from 'moment'

export default {
  name: 'kerjasama.ia',
  props: {
    /**
     * data yang akan tampil.
     */
    // listData: {
    //   type: [String, Array],
    //   default: ''
    // }
  },
  computed: {
    ...mapState('authService', ['token'])
  },
  mounted() {
    /** Innit data */
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFilteredIa(filteredItems) {
      this.totalRowsIa = filteredItems.length
      this.currentPage = 1
    },
    closeModalMitra() {
      this.resetValueMitra()
      this.isModalMitra = false
    },
    resetValueMitra() {
      this.modal.judulPks = null
      this.modal.referensiKerjaSama = null
      this.modal.mitraKerjasama = null
      this.modal.ruangLingkup = null
      this.modal.namaPj = null
      this.modal.nipPj = null
      this.modal.jabatanPj = null
      this.modal.mitra = null
      this.modal.namaMitra = null
      this.modal.namaMengetahui = null
      this.modal.nipMengetahui = null
      this.modal.outcome = null
      this.modal.linkLaporan = null
    },
    /**
     * Get data Kerjasama IA
     */
    async simpanData() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/cetakLakerma?kodePks=${this.modal.kodePks}&jabatanPj=${this.modal.jabatanPj}&nipPj=${this.modal.nipPj}&jabatanMengetahui=${this.modal.jabatanMengetahui}&namaMengetahui=${this.modal.namaMengetahui}&nipMengetahui=${this.modal.nipMengetahui}&outcome=${this.modal.outcome}&linkLaporan=${this.modal.linkLaporan}&idJenisDokumenLakerma=2`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.cetakLakerma = res.data
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Laporan Lakerma IA-${this.modal.kodePks}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
          this.$toast.success('File berhasil diunduh')
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.isModalMitra = false
      return result
    },
    async cariLakerma(kodeIa, idIa) {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDetailLapkerma?kodePks=${kodeIa}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataDetail = res.data
          this.formIa = {
            ...this.listDataDetail
          }
          this.modal = this.formIa[0]
          this.modal.idPksHeader = idIa
          this.getDokumenLakerma_(idIa)
        })
        .catch(err => {
          this.isTampil = false
          this.$toast.error('Data tidak ditemukan')
          throw new Error(err)
        })
      console.log(this.listDataDetail[0], 'sssssss', this.modal)
      this.isModalMitra = true
      return result
    },
    async getListTransaksiIa_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListTransaksiIa`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data
          /** Set the initial number of items */
          this.totalRowsIa = this.listData.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Show dokumen lakerma
     */
    async showDokumenLakerma() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenLakerma?idPksHeader=${this.modal.idPksHeader}&idJenisDokumenLakerma=2`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Laporan Lakerma PKS-${this.modal.kodePks}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    /**
     * Get dokumen LAKERMA
     */
    async getDokumenLakerma_(idIa) {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenLakerma?idPksHeader=${idIa}&idJenisDokumenLakerma=2`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          console.log('dok lakerma: ', res.data)
          this.dataDokumenLakerma = res.data
        })
        .catch(err => {
          throw new Error(err)
        })
      return result
    },
    /**
     * Upload dokumen lakerma
     */
    async uploadDokumenLakerma() {
      console.log('Upload', this.modal)
      const bodyFormData = new FormData()
      bodyFormData.append('idPksHeader', this.modal.idPksHeader)
      bodyFormData.append('dokumenLakerma', this.modal.dokumenLakerma)
      bodyFormData.append('idJenisDokumenLakerma', 2)
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/uploadDokumenLakerma`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
          this.getDokumenLakerma_(this.modal.idPksHeader)
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * INPUT => Upload Dokumen
     */
    async inputUploadDokumen() {
      if (this.modal.dokumenLakerma?.type !== 'application/pdf') {
        this.modal.dokumenLakerma = null
        this.$toast.error('Dokumen harus format PDF (.pdf)')
      }
    },
    /**
     * Show dokumen ks
     */
    async showDokumenKs(idIa, nomorSuratUt) {
      console.log('this.listData.idIa', idIa, nomorSuratUt)
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getCetakanIa?idIA=${idIa}`,
        method: 'POST',
        responseType: 'blob',
        // data: { idIa: idIa },
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Dokumen_IA_${idIa}_${nomorSuratUt}`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get List Data */
      this.isLoadingIa = true
      await Promise.all([this.getListTransaksiIa_()])
      this.isLoadingIa = false
    }
  },
  data() {
    return {
      dataDokumenLakerma: null,
      listIdGroup: ID_GROUP,
      idGroup: store.state.authService.idGroup,
      // Tabel IA Options
      isModalMitra: false,
      isLoadingIa: false,
      filterIa: null,
      perPageIa: 5,
      pageOptionsIa: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageIa: 1,
      totalRowsIa: 1,
      // Date Picker
      basicConfig: {
        // dateFormat: 'd-m-Y'
      },
      modal: {
        kodePks: null,
        judulPks: null,
        referensiKerjaSama: null,
        mitraKerjasama: null,
        ruangLingkup: null,
        namaPj: null,
        nipPj: null,
        jabatanPj: null,
        mitra: null,
        namaMitra: null,
        namaMengetahui: null,
        nipMengetahui: null,
        outcome: null,
        linkLaporan: null,
        idPksHeader: null,
        dokumenLakerma: null
      },
      basicMinTimeConfig: {
        minDate: moment(new Date()).format('YYYY-MM-DD')
      },
      // Table
      thead: [
        {
          label: 'Kode IA',
          key: 'kodeIa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'No. Surat UT',
          key: 'nomorSuratUt',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'No. Surat Mitra',
          key: 'nomorSuratMitra',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tanggal Surat',
          key: 'tanggalSurat',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tempat Kegiatan',
          key: 'tempatAcara',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tanggal Acara',
          key: 'tanggalAcara',
          class: 'text-center',
          sortable: true
        },
        // { label: 'Kontak Mitra', key: 'kontakMitra', class: 'text-center' },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ],
      listData: [],
      listDataDetail: []
    }
  }
}
</script>
